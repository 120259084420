import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const AddRole = () => {
  return (
    <LayoutComponent>
      <div>
        Add New Role to Team
      </div>
    </LayoutComponent>

  )
}

export default AddRole
